
import Vue from 'vue';
import Component from 'vue-class-component';
import CustomTable from '@/core/components/shared/CustomTable.vue';
import { TYPES } from '@/core/config/Types';
import { Inject } from 'inversify-props';
import { Prop } from 'vue-property-decorator';
import { Seal } from '@/wms/domain/preRegister/Seal';
import { BillOfLanding } from '@/wms/domain/billOfLanding/BillOfLanding';
import { SearchTerritoryByQueryDescription } from '@/settings/application/uses_cases/views/territories/SearchTerritoryByQueryDescription';
import { Territories } from '@/settings/domain/views/territories/Territories';
import { EntityFindFilter } from '@/settings/application/uses_cases/entity/search/EntityFindFilter';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { default as BillOfLadingTemplate } from '@/wms/infrastructure/ui/allDocuments/MasterBillOfLading.vue';
import { default as AirWayBillTemplate } from '@/wms/infrastructure/ui/allDocuments/AirWayBill.vue';
import Airport from '@/settings/domain/airport/Airport';
import { FindAllAirport } from '@/settings/application/uses_cases/airport/search/FindAllAirport';
import { CheckExistBillOfLanding } from '@/wms/application/billOfLanding/search/CheckExistBillOfLanding';
import { showAlert } from '@/core/plugins/Notifications';
import { EventTransactional } from '@/tracking/domain/eventsTransactional/EventTransactional';
import EntitySearch from '@/core/components/shared/EntitySearch.vue';
import { ActivityTransactionalForTraceability } from '@/tracking/domain/activitiesTransactional/Projections/ActivityTransactionalForTraceability';
import TransactionalEventForTraceability from '@/wms/domain/preRegister/TransactionalEventForTraceability';
import ConsigneeByOperationMultiSelect from '@/core/components/shared/ConsigneeByOperationMultiSelect.vue';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import { CreateBillOfLandingReport } from '@/wms/application/billOfLanding/create/CreateBillOfLandingReport';

@Component({
  name: 'BillOfLandingAddForm',
  components: { CustomTable, BillOfLadingTemplate, AirWayBillTemplate, EntitySearch, ConsigneeByOperationMultiSelect }
})
export default class BillOfLandingAddForm extends Vue {
  @Inject(TYPES.API_VIEW_FIND_TERRITORIES_BY_QUERY)
  readonly searchTerritoriesByQueryParameter!: SearchTerritoryByQueryDescription;
  @Inject(TYPES.ENTITY_FIND_BY_DESCRIPTION_LIKE)
  readonly findEntityByDescription!: EntityFindFilter;
  @Inject(TYPES.AIRPORT_FIND_ALL)
  readonly findAllAirports!: FindAllAirport;
  @Inject(TYPES.BILL_OF_LANDING_CHECK_EXIST)
  readonly checkExistBillOfLanding!: CheckExistBillOfLanding;
  @Inject(TYPES.BILL_OF_LANDING_CREATE_REPORT)
  readonly createBillOfLandingReport!: CreateBillOfLandingReport;

  //Props
  @Prop({ required: true }) form!: BillOfLanding;
  @Prop({ required: true }) actions!: any;
  @Prop({ required: false }) activityData!: ActivityTransactionalForTraceability;
  @Prop({ required: false }) exportToPDF!: Function;
  @Prop() typeTransport!: string;
  @Prop() clientsFromBulks!: Entity[];
  @Prop() master!: PreRegisterForTraceabilityPanel;
  @Prop({ required: false, default: () => new EventTransactional() }) eventData!: TransactionalEventForTraceability;

  //Listas
  sealList: Seal[] = [];
  originList: Territories[] = [];
  destinationList: Territories[] = [];
  customerList: Entity[] = [];
  airportList: Airport[] = [];
  clientSelected: Entity | null = null;
  typeBLSelected: { description: string; id: string } = {
    description: `Master bill of landing`,
    id: 'master'
  };
  typeBL = [
    {
      description: `Master bill of landing`,
      id: 'master'
    },
    {
      description: `House bill of landing`,
      id: 'house'
    }
  ];

  //VARIABLES PARA INTERVALO DE BUSQUEDA
  timeoutOrigin: any;
  timeoutDestination: any;
  timeoutEntity: any;
  searchInterval = 600;

  //Data
  isLoading = false;
  suggestedDate = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substring(0, 10);

  //Getter para obtener la direccion del cliente
  get recipientAddress() {
    return this.form.recipient == null ? '' : this.form.recipient.address.description;
  }

  //Getter para obtener el ZipCode del cliente
  get zipCodeAddress() {
    return this.form.recipient == null ? '' : this.form.recipient.address.zipCode;
  }

  //Getter para obtener el ZipCode del cliente
  get recipientCity() {
    return this.form.recipient == null ? '' : this.form.recipient.address.selectTerritorie;
  }

  //Getter para obtener la direccion del cliente
  get senderAddress() {
    return this.form.sender == null ? '' : this.form.sender.address.description;
  }

  //Getter para obtener el ZipCode del cliente
  get senderZipCodeAddress() {
    return this.form.sender == null ? '' : this.form.sender.address.zipCode;
  }

  //Getter para obtener el ZipCode del cliente
  get senderCity() {
    return this.form.sender == null ? '' : this.form.sender.address.selectTerritorie;
  }

  //Getter para habilitar/deshabilitar el formulario
  get isDisabled() {
    return this.eventData.state === 'EXECUTED';
    // return this.activityData.executed; //Se deshabilita el formulario si la actividad ya fue ejecutada
  }

  //Getter para obtener la lista de shippers que tiene la operacion
  get shippers() {
    // Los shippers no deben repetirse
    // return this.master?.bulks
    //   ?.map(bulk => bulk?.newShipper)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    return [];
  }

  // Getter para obtener la lista de consignee que tiene la operacion
  get consignees() {
    // Los consignees no deben repetirse
    // return this.master?.bulks
    //   ?.map(bulk => bulk?.newConsignee)
    //   .filter((value, index, self) => self.indexOf(value) === index);
    return [];
  }

  async mounted() {
    if (!this.activityData.executed && this.typeTransport === 'Air') {
      await this.findAirports();
    }
  }

  //Funcion invocada para agregar un nuevo precinto a la lista
  addTag(input: string) {
    const newTag = new Seal('', input);
    this.sealList.push(newTag);
    this.form.sealList.push(newTag);
  }

  //Funcion invocada para ejecutar el guardado en el componente
  save() {
    this.actions?.save();
  }

  //Funcion invocada para limpiar el formulario
  clear() {
    this.$bvModal.hide('C10');
    this.actions.clear();
  }

  //Funcion invocada para buscar los territorios
  async getCities(query: string) {
    this.isLoading = true;
    const res = await this.searchTerritoriesByQueryParameter.execute(query.toUpperCase());
    this.isLoading = false;
    return res;
  }

  // Este método es llamado cuando el usuario escribe sobre el input de origen o destino para buscar territorios.
  findTerritoriesFactory(query: string, input: string, delayed = true): void {
    //Limpiamos cualquier función anterior en el timeout para evitar búsquedas repetitivas y solapadas.
    delayed ? clearTimeout(this.timeoutOrigin) : null;
    this.timeoutOrigin = setTimeout(
      () => {
        (async () => {
          if (query.length >= 3) {
            try {
              const res = await this.getCities(query);
              input == 'origin' ? (this.originList = res) : (this.destinationList = res);
            } catch (error) {
              this.isLoading = false;
              throw new Error(`${error}`);
            }
          }
          clearTimeout(this.timeoutOrigin);
        })();
      },
      delayed ? this.searchInterval : 0
    );
  }

  //Funcion invocada para buscar entidades basadas en el nombre
  findEntity(query: string) {
    try {
      clearTimeout(this.timeoutEntity);
      if (query.length < 2) return;
      const params: any = {
        text: query,
        type: ['SUPPLIER', 'CUSTOMER', 'SERVICE', 'OTHERS']
      };
      this.timeoutEntity = setTimeout(() => {
        (async () => {
          this.isLoading = true;
          const res = await this.findEntityByDescription.execute(params);
          this.customerList = res.length > 0 ? res : [];
          this.isLoading = false;
          clearTimeout(this.timeoutEntity);
        })();
      }, this.searchInterval);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Función para obtener listado de aeropuertos
  async findAirports() {
    try {
      this.isLoading = true;
      const res = await this.findAllAirports.execute();

      if ('error' in res || 'message' in res) {
        this.isLoading = false;
        return;
      }

      this.airportList = res;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Función para validar la existencia del BL o AWB
  async validateExistCode(code: string) {
    try {
      this.isLoading = true;
      const validateCode = await this.checkExistBillOfLanding.execute(code);
      if (validateCode) {
        showAlert(
          'warning',
          this.$t('generalmessage.ERROR_REGISTER_TITLE'),
          `${this.$t('generalmessage.ERROR_EXIST_CODE', { code })}`,
          ''
        );

        this.form.serial = '';
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    }
  }

  //Funcion invocada para validar un input
  getValidationState({ dirty, validated, valid = null }: { dirty: any; validated: any; valid: any }) {
    return dirty || validated ? valid : null;
  }

  //Función para armar el label del select de aeropuertos
  airportLabel(airport: Airport) {
    return `${airport.code} - ${airport.city} - ${airport.continent}`;
  }

  createConsignee(query: string) {
    // this.consignees.push(query.trim());
    this.form.newConsignee = query.trim();
  }

  async createReport() {
    try {
      this.isLoading = true;
      // Se obtiene un archivo PDF con la información del BL
      const res = await this.createBillOfLandingReport.execute({
        isMaster: true,
        serial: this.form.serial,
        masterBL: null
      });

      // Tomamos el objeto URL generado por el servicio y descargamos el archivo que ya ha sido convertido en Blob
      const link = document.createElement('a');
      link.href = res;
      link.setAttribute('download', `bill_of_landing_${this.form.serial}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Limpiamos la URL generada
      window.URL.revokeObjectURL(res);
    } catch (error) {
      this.isLoading = false;
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }
}
